
@import 'https://fonts.googleapis.com/css?family=Alfa+Slab+One|Gudea';

a {
	color: #564B3D;
}

// bootstrap row reset
.row {
	margin-left: 0px;
	margin-right: 0px;
}

nav
{
	z-index: 1001;
}

.navbar-brand {
    float: none;
    height: 50px;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
    img {
    	max-width: 310px;
    	@media (max-width: 388px) {
    		max-width: 200px;
    	}
    }
}

.navbar-nav>li>a {

	@media (min-width: 768px) {
		padding-top: 35px;
	}
	text-align: center;
	outline: 0 !important;
    padding-bottom: 15px;

    &:focus, &:active {
    	background: transparent;
    	color: #000;
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 10px 10px;
    margin-top: 30px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
	@media (max-width: 388px) {
		margin-top: 14px;
	}    
}

@media (min-width: 768px) {
	.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
	    margin-left: 0px;
	}
}

.modal-content {
	background: #b0214a;
	color: white;	
	padding: 15px;
	h2 {
		text-align:center;
	}
}

.modal-dialog {		
	  margin: 6em auto;		
	  max-width: 350px;		
	  width: 100%;

	  #submit {
	  	background: #3a3a3a;
	  	color: #fff !important;
	  	width: 100%;
	  	padding: 10px 0;
	  }
}

.banner {
	img {
		border-radius: 5px;
	}
}




.panel-top {
	margin-left: 5%;
    top: 175px;
    position: absolute;
    // margin-right: auto;
    height: auto;
    @media (min-width: 991px) {
    	width: 400px;
    }
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    background-color: #B19F87;
    h1 {
    	text-align: center;
    }
    @media (max-width: 991px) {
    	display:none;
    }    	
}

.panel-top-mobile {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	background-color: #B19F87;
    width: 100%;
    max-width: 400px;	
    border-radius: 5px;
    h1 {
    	padding: 10px;
    	text-align: center;
    }    
}


#signUpForm label {
    vertical-align: top;
    margin: 0 !important;
    padding: 0 !important;
    height: 30px !important;
    line-height: 30px !important;
    float: none !important;
    text-align: right !important;
    font-family: "Open Sans",Calibri,sans-serif !important;
    font-size: 14px !important;
    display: inline-block !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    width: 22% relative !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    color: #F8F8F8 !important;
}

.sec-panel {
	margin-top: 25px;
	background-color: white;
	border-radius: 5px;
	color: #564B3D;
	padding: 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.sec-panel-m {
	@media (min-width: 992px) {
		display: none;
	}	
	margin-top: 25px;
	background-color: white;
	border-radius: 5px;
	color: #564B3D;
	padding: 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.browse {
	margin-top:25px;
	margin-bottom: 25px;
	text-align: center;
	&-button {
		display: block;
		width: 33%;
		margin-left:auto;
		margin-right: auto;
		border: 1px solid black;
		border-radius: 5px;
		padding: 12px;
		font-size:16px;
		font-weight: 700px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: #564B3D;
		}	
	}
}

.join {
	margin-top:25px;
	margin-bottom: 25px;
	text-align: center;
	&-button {
		display: block;
		width: 33%;
		margin-left:auto;
		margin-right: auto;
		border: 1px solid black;
		border-radius: 5px;
		padding: 12px;
		font-size:16px;
		font-weight: 700px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: #564B3D;
		}	
	}
}

body.modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
}

.modal-content {
	background-color: #B19F87;
	color: #f8f8f8;
}

.top-pad {
	background-color: #F8F8F8;
	color: #564B3D; 
	margin-top: 137px;
	border-radius: 5px;
	padding:15px;
}

footer {
	margin-top: 10px;
	color: #564B3D; 
	background: white;
	padding-top: 10px;
}

.padTopBtm {
	padding: 150px 0;
}
.pad {
	margin-top: 20px;
}
.btmPad {
	padding: 200px 0;
	h1 {
		color: white;
	}
	.row {
		background: rgba(176,33,74,0.5);
	}
}

@media (max-width: 550px) {
	.btmPad h1 {
		font-size: 18px;
		color: white;
	}
}
h1 {
	padding: 30px 0;
	font-family: 'Alfa Slab One', cursive;
}
h1.title {
	color: #b0214a;
}
h2,h3 {
	font-family: 'Alfa Slab One', cursive;
	color: #b0214a;
}
p {
	font-family: 'Gudea', sans-serif;
}
.u {
	padding: 10px;
}

.logo {
	margin-top: 10px;
	max-width: 305px;
	@media (max-width: 767px) {
		max-width: 175px;
	}
}

.couple-lucky {
	@media (max-width: 1024px) {
		background-attachment: initial;
	}
	@media (max-width: 991px) {
		height: auto;
	}
}

@media (max-width: 600px) {
	.col-xs-4 {
		width: 100%;
	}
}

input#username, input#password {
	color: black !important;
	width: 100%;
}

@mixin btn1 {
	text-align: center;
	display: block;
	margin: 0 auto !important;
	border: 1px solid #000;
	border-radius: 2px;
	background: #000;
	color: #fff;
	padding: 1em 3em;
}

.btn-default {
	@include btn1;
}